import React from "react";
import { FaAward } from "react-icons/fa";
import { VscFolderLibrary } from "react-icons/vsc";
import "./about.css";

const About = () => {
  return (
    <section id="about">
      <h5>Get To Know</h5>
      <h2>About Me</h2>

      <div className="container about__container">
        <div className="about__me">
          <picture className="about__me-image">
            <source
              media="(max-width: 600px)"
              srcSet="https://ik.imagekit.io/makhloufi/Hassan_Portfolio/tr:w-450/me-about.jpg"
            />
            <img
              loading="lazy"
              src="https://ik.imagekit.io/makhloufi/Hassan_Portfolio/tr:w-800/me-about.jpg"
              alt="me"
            />
          </picture>
        </div>
        <div className="about__content">
          <div className="about__cards">
            <article className="about__card">
              <FaAward className="about__icon" />
              <h5>Experience</h5>
              <small>2+ Years Working</small>
            </article>
            {/* <article className="about__card">
              <FiUsers className="about__icon" />
              <h5>Clients</h5>
              <small>300+ Worldwide</small>
            </article> */}
            <article className="about__card">
              <VscFolderLibrary className="about__icon" />
              <h5>Projects</h5>
              <small>30+ Completed</small>
            </article>
          </div>

          <p>
            I'm a Front End Developer at DataImpact by NielsenIQ, where my
            skills have flourished through both professional endeavors and a
            variety of personal projects. My work spans across building
            ecommerce websites, crafting RESTful APIs, designing admin
            dashboards, creating engaging landing pages, and even replicating
            popular websites like Facebook. I'm always eager to expand my
            knowledge and enhance my capabilities in this ever-evolving field.{" "}
          </p>

          <a href="#contact" className="btn btn-primary">
            Let's Talk
          </a>
        </div>
      </div>
    </section>
  );
};

export default About;
