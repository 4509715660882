import React from "react";
import "./portfolio.css";

const Portfolio = () => {
  return (
    <section id="portfolio">
      <h5>My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className="container portfolio__container">
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img
              loading="lazy"
              src="https://ik.imagekit.io/makhloufi/Hassan_Portfolio/portfolio1.png"
              alt="ProShop"
            />
          </div>
          <h3>ProShop eCommerce Website</h3>

          <div className="portfolio__item-cta">
            <a
              href="https://github.com/HassanMak29/MERN-ProShop-eCommerce"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
            <a
              href="https://brave-lapel-mite.cyclic.app/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img
              loading="lazy"
              src="https://ik.imagekit.io/makhloufi/Hassan_Portfolio/portfolio2.png"
              alt="Cloudsbay"
            />
          </div>
          <h3>Cloudsbay eCommerce Website</h3>

          <div className="portfolio__item-cta">
            <a
              href="https://github.com/HassanMak29/Cloudsbay-ecommerce-front"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
            <a
              href="https://cloudsbay-hassan.netlify.app/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img
              loading="lazy"
              src="https://ik.imagekit.io/makhloufi/Hassan_Portfolio/portfolio3.png"
              alt="Natours"
            />
          </div>
          <h3>Natours Website - Book tours and explore the world</h3>

          <div className="portfolio__item-cta">
            <a
              href="https://github.com/HassanMak29/natours-backend"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
            <a
              href="https://natours-hassan.netlify.app/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img
              loading="lazy"
              src="https://ik.imagekit.io/makhloufi/Hassan_Portfolio/portfolio4.png"
              alt="Weather app"
            />
          </div>
          <h3>Weather App</h3>

          <div className="portfolio__item-cta">
            <a
              href="https://github.com/HassanMak29/javascript-weatherapp"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
            <a
              href="https://javascript-weatherapp-hassan.netlify.app/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img
              loading="lazy"
              src="https://ik.imagekit.io/makhloufi/Hassan_Portfolio/portfolio5.png"
              alt="Forkify App"
            />
          </div>
          <h3>Forkify - Find your favourite recipes </h3>

          <div className="portfolio__item-cta">
            <a
              href="https://github.com/HassanMak29/forkify-course-project"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
            <a
              href="https://forkify-course-project-hassan.netlify.app/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img
              loading="lazy"
              src="https://ik.imagekit.io/makhloufi/Hassan_Portfolio/portfolio6.png"
              alt="Omnifood landing page"
            />
          </div>
          <h3>
            Omnifood - Food that you love, cooked and delivered to your door
            (landing page)
          </h3>

          <div className="portfolio__item-cta">
            <a
              href="https://github.com/HassanMak29/Omnifood-course-project"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
            <a
              href="https://omnifood-hassan.netlify.app/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img
              loading="lazy"
              src="https://ik.imagekit.io/makhloufi/Hassan_Portfolio/portfolio7.png"
              alt="Natours landing page"
            />
          </div>
          <h3>Natours - Tours in Nature (landing page)</h3>

          <div className="portfolio__item-cta">
            <a
              href="https://github.com/HassanMak29/natours-css"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
            <a
              href="https://natours-css-hassan.netlify.app"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img
              loading="lazy"
              src="https://ik.imagekit.io/makhloufi/Hassan_Portfolio/portfolio8.png"
              alt="Trillo landing page"
            />
          </div>
          <h3>Trillo - Your booking app (landing page)</h3>

          <div className="portfolio__item-cta">
            <a
              href="https://github.com/HassanMak29/trillo-css"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
            <a
              href="https://trillo-hassan.netlify.app/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article>
        <article className="portfolio__item">
          <div className="portfolio__item-image">
            <img
              loading="lazy"
              src="https://ik.imagekit.io/makhloufi/Hassan_Portfolio/portfolio9.png"
              alt="Bankist app"
            />
          </div>
          <h3>Bankist App</h3>

          <div className="portfolio__item-cta">
            <a
              href="https://github.com/HassanMak29/javascript-bankist-app"
              className="btn"
              target="_blank"
              rel="noreferrer"
            >
              GitHub
            </a>
            <a
              href="https://javascript-bankist-app.netlify.app/"
              className="btn btn-primary"
              target="_blank"
              rel="noreferrer"
            >
              Live Demo
            </a>
          </div>
        </article>
      </div>
    </section>
  );
};

export default Portfolio;
