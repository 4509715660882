import axios from "axios";
import { useEffect, useState } from "react";

const CTA = () => {
  const [CVId, setCVId] = useState("");

  const getCV = async () => {
    const response = await axios.get(
      "https://l9yaovv4yg.execute-api.eu-north-1.amazonaws.com/cv-id"
    );
    setCVId(response.data.result);
  };

  useEffect(() => {
    getCV();
  }, []);

  return (
    <div className="cta">
      <a
        href={`https://imagekit.io/api/v1/files/${CVId}/download?fileName=cv.pdf`}
        download="Hassan_makhloufi_CV"
        className="btn"
      >
        Download CV
      </a>
      <a href="#contact" className="btn btn-primary">
        Let's Talk
      </a>
    </div>
  );
};

export default CTA;
