import React from "react";
import CTA from "./CTA";
import "./header.css";
import HeaderSocials from "./HeaderSocials";

const Header = () => {
  return (
    <header id="header">
      <div className="container header__container">
        <h5>Hello I'm </h5>
        <h1>Hassan Makhloufi</h1>
        <h5 className="text-light">MERN Stack Developer</h5>
        <CTA />
        <HeaderSocials />

        <picture className="me">
          <source
            media="(max-width: 380px)"
            srcSet="https://ik.imagekit.io/makhloufi/Hassan_Portfolio/tr:w-450/me.jpg"
          />
          <img
            src="https://ik.imagekit.io/makhloufi/Hassan_Portfolio/me.jpg"
            alt="me"
          />
        </picture>

        <a href="#contact" className="scroll__down">
          Scroll Down
        </a>
      </div>
    </header>
  );
};

export default Header;
